import React, { Fragment } from 'react';
import { StrapiImage } from '../../types';
import NextImage, { ImageProps } from 'next/image';
import { getStrapiMedia } from '../../lib/media';
import { toBase64 } from '../../utils/toBase46';
import { createShimmer } from '../../utils/createShimmer';

type IBaseImage = ImageProps & {
  src: string;
};
function BaseImage({ src, ...rest }: IBaseImage) {
  return (
    <NextImage layout="responsive" objectFit="cover" src={src} {...rest} />
  );
}

type IDynamicImage = Omit<IBaseImage, 'src' | 'alt'> & {
  blur?: boolean;
  image: StrapiImage;
  width?: number;
  height?: number;
  alt?: string;
};

function DynamicImage({ image, blur, alt,  ...rest }: IDynamicImage) {

  if(!image) {
    return <></>
  }

  const { alternativeText, width, height } = image.attributes;

  const otherProps: { placeholder: 'blur' | 'empty'; blurDataURL?: string } = {
    placeholder: 'empty',
  };

  if (blur) {
    otherProps.placeholder = 'blur';
    otherProps.blurDataURL = toBase64(
      createShimmer(rest.width || width, rest.height || height)
    );
  }

  return getStrapiMedia(image).map( url =>
    <BaseImage
      src={url}
      width={rest.width || width}
      height={rest.height || height}
      alt={alternativeText || alt || ''}
      {...rest}
      {...otherProps}
    />
  ).unwrapOr(<Fragment />);
}

export { BaseImage as StaticImage, DynamicImage };
